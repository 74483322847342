import { Injectable } from '@angular/core';
import {
	TableViewColumnInterface,
	TableViewCreateResponse,
	TableViewGetResponse,
	UserActionLimitsResponse,
	UserActionLimitsUpdateRequestInterface,
	UserInterface,
	UserPinnedSearchParam,
	UserUpdateInterface,
} from '@reach/interfaces';
import { Observable } from 'rxjs';
import {
	TableView,
	TableViews,
} from '~app-client-item/search/core/components/node-table/types/table';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class UsersApiService extends BaseApiService {
	protected commonPath = ['users'];

	public getUsernameConflicts(username: string): Promise<boolean> {
		const params = { username };

		return this.http
			.get<{ username?: boolean; email?: boolean }>(this.url('conflicts'), params)
			.then((response) => {
				return response.username || false;
			});
	}

	public getEmailConflicts(email: string): Promise<boolean> {
		const params = { email };

		return this.http
			.get<{ username?: boolean; email?: boolean }>(this.url('conflicts'), params)
			.then((response) => {
				return response.email || false;
			});
	}

	public increaseActionLimits(
		body: UserActionLimitsUpdateRequestInterface
	): Promise<UserActionLimitsResponse> {
		return this.http.patch<UserActionLimitsResponse>(
			this.url('me', 'action-limits', 'increase'),
			body
		);
	}

	public getActionLimits(): Promise<UserActionLimitsResponse> {
		return this.http.get<UserActionLimitsResponse>(this.url('me', 'action-limits'));
	}

	public editData(data: Partial<UserUpdateInterface>): Promise<UserInterface> {
		const body = data;
		return this.http.patch<UserInterface>(this.url('me', 'info'), body);
	}

	public requestDelete(): Promise<void> {
		return this.http.post<void>(this.url('me', 'request-delete'));
	}

	public deleteUserWithToken(token: string): Promise<string> {
		return this.http
			.delete<{ token: string }>(this.url('me'), {}, { 'x-access-token': token })
			.then((response) => {
				return response?.token;
			});
	}

	public sendDeleteUserFeedback(token: string, reasons: string[]): Promise<void> {
		return this.http.post<void>(
			this.url('me', 'deletion-motive'),
			{ motives: reasons },
			{},
			{ 'x-access-token': token }
		);
	}

	public claimBirthdayPresent(): Promise<void> {
		return this.http.post<void>(this.url('me', 'claim-birthday-present'));
	}

	public getPinnedSearchParams(): Promise<string[]> {
		return this.http.get<string[]>(this.url('me', 'pinned-search-params'));
	}
	public editPinnedSearchParams(params: string[]): Promise<void> {
		return this.http.put<void>(this.url('me', 'pinned-search-params'), {
			pinnedSearchParams: params as UserPinnedSearchParam[],
		});
	}
	public getTableViews(): Promise<TableViews> {
		return this.http.get<TableViews>(this.url('me', 'table-views'));
	}
	public createTableView(name: string, columns: TableViewColumnInterface[]): Promise<TableView> {
		const body = {
			name: name,
			columns: columns,
		};
		return this.http.post<TableView>(this.url('me', 'table-views'), body);
	}

	public editTableView(
		tableViewId: string,
		name: string,
		columns: TableViewColumnInterface[]
	): Promise<TableView> {
		const body = {
			name: name,
			columns: columns,
		};
		return this.http.put<TableView>(this.url('me', 'table-views', tableViewId), body);
	}

	public deleteTableView(tableViewId: string): Promise<TableView> {
		return this.http.delete<TableView>(this.url('me', 'table-views', tableViewId));
	}
}
